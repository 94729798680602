<template>
  <div class="container">
    <div class="body">
      <div class="menu">
        <slot name="menu"/>
      </div>
      <slot name="content"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Layout"
}
</script>
<style>
a {
  text-decoration: none;
  color: black;
}

a:visited {
  text-decoration: none;
  color: black;
}

.body {
  font-size: 1.2em;
}

.menu pre span, button {
  cursor: pointer;
}
</style>

<style scoped>
div.menu {
  height: 35px;
  text-align: center;
}

div.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

div.body {
  display: table;
}

</style>
