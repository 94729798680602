import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'

const app = createApp(App);
app.use(router);
app.use(VueAxios, axios);
app.axios.defaults.baseURL = process.env.VUE_APP_KIRBY_URL;
app.mount('#app');
