<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  mounted () {
    document.title = 'Pascal Sidler';
  }
}
</script>

<style>
</style>
