<template>
  <Layout>
    <template #menu>
      <pre><router-link to="/">works</router-link></pre>
    </template>
    <template #content>
      <pre v-if="cv">{{cv}}</pre>
      <pre v-else>Loading...</pre>
    </template>
  </Layout>
</template>

<script>
import axios from "axios";
import Layout from "@/components/Layout";

export default {
  name: "Cv",
  components: {Layout},
  data () {
    return {
      title: null,
      cv: null,
    }
  },
  mounted () {
    //axios.get('https://localhost:7300/kirby/bilder')
    axios.get('/kirby/cv')
        .then(response => {
          this.cv = response.data[0].text;
          this.title = response.data[0].title;
        }).catch(reason => {
      console.log("request ist fehlgeschlagen: ");
      console.log(reason);
    });
  },
}
</script>

<style scoped>

</style>
