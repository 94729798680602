<template>
  <Layout>
    <template #menu>
      <pre><button @click="previousImage()">&lt;</button>  <button @click="nextImage()">&gt;</button>  <router-link to="/cv">cv</router-link></pre>
    </template>
    <template #content>
      <img v-if="currentUrl" class="bild" :src="currentUrl"
           :alt="currentDescription"
           @load="imageLoaded"
           @click="nextImage()" />
      <pre class="description">{{currentDescription}}</pre>
    </template>
  </Layout>

</template>

<script>
import axios from "axios";
import Layout from "@/components/Layout";

//const delay = ms => new Promise(res => setTimeout(res, ms)); // TODO

export default {
  name: "Bilder",
  props: ['imageId'],
  components: {Layout},
  data () {
    return {
      bilder: null,
      current: 0,
      currentUrl: null,
      currentDescription: null,
      loading: true,
    }
  },
  methods: {
    previousImage() {
      if(!this.loading) {
        const max = this.bilder.length;
        this.loading = true;
        this.current = (((this.current - 1) % max)+max)%max;
      }
    },
    nextImage() {
      if(!this.loading) {
        this.loading = true;
        this.current = ((this.current + 1) % this.bilder.length);
      }
    },
    imageLoaded() {
      this.loading = false;
      this.currentDescription = this.bilder[this.current].beschreibung;
    }
  },
  mounted () {
    if(this.$route.params.imageId) {
      this.current = this.$route.params.imageId
    }

    axios.get('/kirby/bilder')
        .then(response => (this.bilder = response.data)).catch(reason => {
      console.warn("request ist fehlgeschlagen: ");
      console.warn(reason);
    });
  },
  watch: {
    bilder: function (val) {
      this.currentDescription = val[this.current].beschreibung;
      this.currentUrl = val[this.current].imageurl;
    },
    current: function () {
      this.currentUrl = this.bilder[this.current].imageurl;
      this.$router.push(this.current.toString())
    },
  }
}
</script>

<style scoped>
button {
  border:none;
  background-color:transparent;
  outline:none;
}

img.bild {
  max-height: calc(100vh - 150px);
  max-width: 100%;
}

pre.description {
  white-space: pre-wrap;
  display: table-caption;
  caption-side: bottom;
  margin: 15.6px 0 0 0;
  text-align: center;
}

</style>
