import { createRouter, createWebHistory } from 'vue-router'
import Bilder from "@/components/Bilder";
import Cv from "@/components/Cv";

const routes = [
  {
    path: '/:imageId?',
    name: 'works',
    component: Bilder,
    props: true,
  },
  {
    path: '/cv',
    name: 'cv',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')

    // statt lazy loading (siehe oben) lade ich es direkt. kostet ja nix.
    component: Cv
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
